import React from "react";
import SponsorsList from "./sponsorsList";
import { StyledPrimaryHeader } from "../../components/styled";

const SponsorBox = ({ sponsorsWithLevels }) => {
  return (
    <div id="sponsors">
      <br />
      <StyledPrimaryHeader>SPONSORS</StyledPrimaryHeader>
      <SponsorsList sponsorsWithLevels={sponsorsWithLevels} />
    </div>
  );
};

export default SponsorBox;
