import styled from "styled-components";

export const StyledSponsorBooth = styled.div`
  display: grid;
  grid-template-columns: 430px 1fr;
  grid-gap: 40px;
  max-width: 1200px;
  .sponsorImage {
    width: 100%;
    margin: auto;
    text-align: center;
    img {
      max-width: 204px;
    }
  }
  .boothBtn:first-of-type {
    margin-top: 50px;
  }
  .boothBtn {
    display: block;
    font-family: "Metropolis", sans-serif;
    font-size: 20px;
    letter-spacing: 3px;
    border-radius: 0px;
    margin: auto;
    margin-bottom: 30px;
    max-width: 260px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #8733ff;
    text-decoration: none;
    cursor: pointer;
  }
  .boothBtn:hover {
    background-color: #000;
  }
  .boothFeature.active,
  .boothFeature {
    padding-top: 60px;
    padding-right: 60px;
    padding-bottom: 80px;
    .boothContain {
      padding-top: 20px;
      .ytvid {
        width: 100%;
        height: 362px;
        @media only screen and (min-width: 1201px) and (max-width: 1440px) {
          height: 375px;
        }
      }
      .sponAbout {
        margin-top: 37px;
        font-family: "Metropolis", sans-serif;
        font-size: 18px;
        text-align: right;
        background-color: #fff;
        .sponBox {
          text-align: left;
        }
        .sponBox.active {
          max-height: 80px;
          overflow-y: auto;
        }
        .readMoreBtn {
          color: #ee0000;
          cursor: pointer;
        }
        .readMoreBtn:hover {
          color: #000;
        }
        .readMoreBtn.active {
          display: none;
        }
      }
    }
    .resourceBackBtn {
      cursor: pointer;
      color: #000;
      float: right;
      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    .resourceWindow {
      max-height: 375px;
      overflow-y: auto;
      padding-right: 10px;
      background-color: #fff;
    }
    .resourceItem {
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-column-gap: 10px;
      .resourceTitle {
        margin: auto 0;
      }
      .viewContain {
        text-align: right;
        .viewBtn {
          display: block;
          font-family: "Metropolis", sans-serif;
          font-size: 12px;
          letter-spacing: 3px;
          color: #fff;
          background-color: #8733ff;

          max-width: 180px;
          text-align: center;
          border-radius: 0px;
          padding: 8px;
          text-decoration: none;
        }
        .viewBtn:hover {
          background-color: #000;
        }
      }
    }
  }
  /*@media only screen and (min-width: 768px) and (max-width: 1200px) {
    grid-template-columns: 220px 1fr;
    padding-left: 20px;
    .sponsorImage{
      padding-right: 30px;
    }
    .boothBtn{
      max-width: 200px;

    }
  }*/

  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .ytvid {
      height: 325px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    grid-template-columns: 380px 1fr;
    .boothFeature {
      padding-right: 30px;
      .boothContain {
        .ytvid {
          height: 320px;
        }
      }
    }
  }
  @media only screen and (min-width: 960px) and (max-width: 1024px) {
    grid-template-columns: 300px 1fr;
    grid-gap: 50px !important;
    .boothFeature {
      padding-right: 30px;
      .boothContain {
        padding-right: 12px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    grid-template-columns: 300px 1fr;
    min-height: 580px;
    align-items: center;
    grid-gap: 50px;
    padding-inline: 25px;

    .sponsorImage {
      max-width: 100vw !important;
      width: 100%;
      transform: scale(0.9);
    }

    .boothFeature {
      padding-right: 30px;
      .boothContain {
        padding-right: 12px;
        .ytvid {
          height: 250px;
        }
      }
    }
  }

  @media only screen and (min-width: 120px) and (max-width: 767px) {
    .boothBtn {
      max-width: 200px;
    }
    .boothFeature {
      padding: 0px;
      .boothContain {
        padding-right: 0px;
        .ytvid {
          height: 250px;
        }
      }
      .sponAbout {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    .boothFeature.active {
      padding-left: 30px;
      .resourceWindow {
        max-height: 100%;
      }
      .resourceItem {
        grid-template-columns: 1fr;
        .viewContain {
          margin-top: 10px;
        }
      }
    }
    display: block;
  }
`;

export const StyledSponsorGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 258px);
  grid-gap: 30px;
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  margin-bottom: 100px;
  .sponsor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 150px;
    background: #fff;
    border: 2px solid #f4f4f4;
    cursor: pointer;
    text-align: center;
  }
  .sponsor:hover {
    border: 2px solid #000;
  }
  @media only screen and (max-width: 1268px) {
    grid-template-columns: repeat(3, 278px);
  }
  @media only screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 316px);
    .sponsor {
      width: 300px;
      height: 175px;
    }
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 200px);
    .sponsor {
      width: 200px;
    }
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 80%);
    .sponsor {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 414px) {
    grid-template-columns: repeat(2, 180px);
    grid-gap: 25px;
    .sponsor {
      width: 180px;
    }
  }
  @media only screen and (max-width: 375px) {
    grid-template-columns: repeat(2, 160px);
    grid-gap: 25px;
    .sponsor {
      width: 160px;
    }
  }
  @media only screen and (max-width: 320px) {
    grid-template-columns: repeat(2, 132px);
    grid-gap: 25px;
    .sponsor {
      width: 132px;
    }
  }
  @media only screen and (max-width: 280px) {
    grid-template-columns: repeat(2, 120px);
    grid-gap: 15px;
    .sponsor {
      width: 120px;
    }
  }
`;

export const StyledSponsorList = styled.section`
  background-color: #fff;
  .sponsor-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    .sponsor {
      width: 240px;
      padding: 20px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  hr {
    margin-bottom: 65px;
  }
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  margin-bottom: 100px;
  @media only screen and (max-width: 960px) {
    .sponsor {
      width: 300px;
      height: 175px;
    }
  }
  @media only screen and (max-width: 768px) {
    .sponsor {
      width: 200px;
    }
  }
  @media only screen and (max-width: 500px) {
    .sponsor {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 414px) {
    .sponsor {
      width: 180px;
    }
  }
  @media only screen and (max-width: 375px) {
    .sponsor {
      width: 160px;
    }
  }
  @media only screen and (max-width: 320px) {
    .sponsor {
      width: 132px;
    }
  }
  @media only screen and (max-width: 280px) {
    .sponsor {
      width: 120px;
    }
  }
`;
