import styled from "styled-components";

export const StyledPrimaryHeader = styled.h1`
  font-family: "Inter", sans-serif;
  font-size: 62px;
  letter-spacing: 0.8px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  /*@media only screen and (max-width: 1268px) {
    max-width: 890px;
  }
  @media only screen and (max-width: 960px) {
    max-width: 660px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 425px;
    padding-right: 15px;
    padding-left: 15px;
  }*/
`;

export const StyledParagraph = styled.div`
  font-family: "Metropolis-SemiBold", sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1268px) {
    max-width: 890px;
  }
  @media only screen and (max-width: 960px) {
    max-width: 660px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 425px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const StyledSecondaryHeader = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 22px;
  letter-spacing: 3px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #8733ff;
  @media only screen and (max-width: 1268px) {
    max-width: 890px;
  }
  @media only screen and (max-width: 960px) {
    max-width: 660px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 425px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;
