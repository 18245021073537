import React, { FunctionComponent } from "react";

interface sponsorProps {
  url: string;
  logo: string;
  name: string;
  width: string;
}

const Sponsor: FunctionComponent<{ sponsor: sponsorProps }> = ({
  sponsor,
}: {
  sponsor: sponsorProps;
}) => {
  return (
    <li className="sponsor item">
      <a href={sponsor.url} target="_blank">
        <img
          src={sponsor.logo}
          title={sponsor.name}
          alt={sponsor.name}
          width={sponsor.width}
        />
      </a>
    </li>
  );
};

export default Sponsor;
