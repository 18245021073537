import { RefObject, useEffect, useMemo, useState } from "react"

export const useElementVisible = (options:IntersectionObserverInit, targetRef:RefObject<Element>) => {
  const [isVisible, setIsVisible] = useState<boolean>()
  const optionsMemo = useMemo(() => options, [options])

  const handleCallback:IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
    // run callback function
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleCallback, optionsMemo)
    const currentTarget = targetRef.current
    if(currentTarget) observer.observe(currentTarget)
    return () => {
      if(currentTarget) observer.unobserve(currentTarget);
    }
  }, [targetRef, optionsMemo])
  
  return isVisible
}

export const useVisibleElements = ({ targets =[] }) => {
  useEffect(()=>{
    // WE SHOULD BE ABLE TO TRACK MULTIPLE TARGETS
    const observer = new IntersectionObserver()
  })
}
export default useElementVisible