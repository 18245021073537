import { useLocation } from "@reach/router";
import { Link, withPrefix } from "gatsby";
import React, { useContext } from "react";
import Context from "../../context/Context";
import useAuthModal from "../../hooks/useAuthModal";
import { LoginButton } from "../buttons";
import { Social } from "../interfaces";
import { AuthLink } from "../links";
import { SocialSection } from "../styled";

const NewHero = () => {
  const { opened, openModal, user, signOut } = useAuthModal();
  const data = useContext(Context);
  const { socialShare } = data;
  const { pathname } = useLocation();
  const virtual = pathname.includes("virtual");

  return (
    <>
      <div className="new-hero desktop">
        {/*<AuthLink to={"/virtual/live"}>On Demand</AuthLink>*/}
        <img
          src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2024/ITMod-Desktop-Header.png"
          className="title-image"
        />
      </div>
      {user ? (
        <Link to={"/virtual/live"}>
          <div className={virtual ? "new-hero virtual" : "new-hero mobile"}>
            <img
              src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2024/ITMod-Desktop-Header.png"
              className="title-image"
            />
          </div>
        </Link>
      ) : (
        <AuthLink
          key={"nav-hero"}
          to={"/?"}
          className="page mobile-hero-container"
          target="_self"
        >
          <div className={virtual ? "new-hero virtual" : "new-hero mobile"}>
            <img
              src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2024/ITMod-Desktop-Header.png"
              className="title-image"
            />
          </div>
        </AuthLink>
      )}

      <div className="socials">
        {true &&
          socialShare?.map((social: Social, index: number) => (
            <SocialSection key={index}>
              <a href={social.path} target="__blank">
                <img src={withPrefix(social.img)} alt={social.alt} />
              </a>
            </SocialSection>
          ))}
      </div>
    </>
  );
};

export default NewHero;
