import React, { useContext } from "react";
import { StyledPrimaryHeader } from "../../components/styled";
import Context from "../../context/Context";
import Hashtag from "../hashtag";

const SponsorBox = () => {
  const data = useContext(Context);
  const { email, emailSubject, siteName } = data;

  return (
    <>
      <div id="becomeasponsor">
        {/*<Hashtag />*/}
        <StyledPrimaryHeader>Become A Sponsor!</StyledPrimaryHeader>
        <div className="spontextgrid">
          <div>
            <p>
              Download a PDF for more information about sponsoring the IT
              Modernization & AI Summit! Contact us at&nbsp;
              <a
                href={`mailto:${email}?subject=${emailSubject}`}
                target="_blank"
                id="sponsor-email-link"
              >
                {email}
              </a>
            </p>
          </div>

          <a
            href={`mailto:${email}?subject=${emailSubject}`}
            className="pdfButton"
            target="_blank"
          >
            Contact Us
          </a>
        </div>
      </div>
    </>
  );
};

export default SponsorBox;
