import React, { FunctionComponent, ReactNode, useContext, useEffect, useRef } from "react";
import Context from "../../context/Context";
import useElementVisible from "../../hooks/useElementVisible";
import { LayoutProps } from "../interfaces";

export interface SectionProps {
  className?: string;
  children?: ReactNode;
  id?: string;
}
const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.2
}

const Section: FunctionComponent<SectionProps> = ({ children, className, id }) => {
  const { setActiveLink } = useContext(Context)
  const sectionRef = useRef(null)
  const isVisible = useElementVisible(options, sectionRef)
  
  useEffect(() => {
    if(isVisible && id){
      setActiveLink(id)
    }
  }, [isVisible, id])

  return (
    <section className={`section ${className}`} ref={sectionRef}>
      <div className="section-container">{children}</div>
    </section>
  );
};

export default Section;
