import React, { FunctionComponent } from "react";
import SponsorLevel from "./sponsorLevel";
import { StyledSponsorList } from "../styled/sponsor";

const SponsorsList: FunctionComponent = ({ sponsorsWithLevels }) => {
  const sponsorLevels = [];
  Object.keys(sponsorsWithLevels).forEach((sponsorLevel) => {
    sponsorLevels.push(
      <SponsorLevel
        name={sponsorLevel}
        key={sponsorLevel}
        sponsors={sponsorsWithLevels[sponsorLevel]}
      />
    );
  });

  return <StyledSponsorList>{sponsorLevels}</StyledSponsorList>;
};

export default SponsorsList;
