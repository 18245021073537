import React, { useContext, useEffect, useState } from "react";
import About from "../../components/about";
import PresentedInfo from "../../components/presented";
import Section from "../../components/section";
import Speakers from "../../components/speakers";
import SponsorBox from "../../components/sponsor/sponsorBox";
import Sponsors from "../../components/sponsor/sponsors";
import VenueInfo from "../../components/venue";
import Context from "../../context/Context";
import NewHero from "../hero/new";
import { Speaker } from "../interfaces";

const Main = () => {
  const data = useContext(Context);
  const speakers: Speaker[] = data.speakers;
  const { singlePage, sponsors, sponsorLevels } = data;
  const levels = sponsorLevels ? sponsorLevels[0].levels : ["diamond"];
  const [sponsorsWithLevels, setSponsorsWithLevels] = useState({});

  useEffect(() => {
    const sWithLevels = {};

    levels.map((sponsorLevel) => {
      const sponsorsAtLevel = sponsors.filter((sponsor) => {
        if (!sponsor.name.includes("Fortinet Federal")) {
          return sponsorLevel === sponsor.sponsorLevel;
        }
      });
      sWithLevels[sponsorLevel] = sponsorsAtLevel;
    });

    setSponsorsWithLevels(sWithLevels);
  }, []);

  return (
    <>
      <NewHero />
      {singlePage && (
        <div style={{ marginBottom: "100px" }}>
          <Section id="About">
            <About />
          </Section>
          {/* <Section id="Speakers">
            <Speakers speakers={speakers} />
          </Section> */}
          <Section id="Sponsors" className="border-top">
            <SponsorBox />
          </Section>
          <Sponsors sponsorsWithLevels={sponsorsWithLevels} />
        </div>
      )}
    </>
  );
};

export default Main;
