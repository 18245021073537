import React, { FunctionComponent } from "react";
import Sponsor from "./sponsor";

const SponsorLevel: FunctionComponent = ({ name, sponsors }) => {
  return (
    <>
      {/* <h2>{name}</h2>
      <hr /> */}
      <ul className="sponsor-list">
        {sponsors.map((sponsor) => {
          return <Sponsor key={sponsor.name} sponsor={sponsor} />;
        })}
      </ul>
    </>
  );
};

export default SponsorLevel;
