import { Link } from "gatsby";
import React from "react";
import useAuthModal from "../../hooks/useAuthModal";
import useEventDetails from "../../hooks/useEventDetails";
import { LoginButton } from "../buttons";
import { AuthLink, GatedLink } from "../links";
import { ArticleBar } from "./aboutStyles";

const Articles = (props) => {
  const { virtual } = useEventDetails();
  const { opened, openModal, user, signOut } = useAuthModal();

  return (
    <ArticleBar id="about">
      <div className="wrapUp">
        {virtual ? (
          <>
            <div className="abtext">
              <div className="venue-info">
              11.12.24 <span>| 11:00 AM - 2:00 PM ET</span>
                <br />
                <h2 className="virtHeader">VIRTUAL EVENT</h2>
              </div>
              Digital transformation is more essential now than ever for state
              and local governments, as well as higher education institutions.
              Only a few years removed from a period of crisis decision making
              and sped up timelines, technology leaders are continuing to chase
              modernization and digital expansion and ready to embrace the
              change that comes along with it.
              <br />
              <br />
              In addition, citizens continue to clamor for a better digital
              experience, meaning government agencies and universities are
              required to modernize and transform at the same time, all while
              trying to keep up with — and embrace — emerging technologies like
              artificial intelligence.
              <br />
              <br />
              This virtual summit will bring together technology leaders from
              across higher education, as well as state and local governments to
              explore the ongoing challenges they face while embracing IT
              modernization and digital transformation. Leaders will gather to
              discuss improving digital services, modernizing technology,
              embracing change and developing a workforce to support
              future-ready growth.
              <br />
              <br />
            </div>
            <div className="about-text">
              <div className="aboutfirst">
                <AuthLink to="/virtual/live" className="page login">
                  Register
                </AuthLink>
              </div>
              <div className="aboutfirst">
                <Link to="/#becomeasponsor" className="page login">
                  Sponsor
                </Link>
              </div>
              <div className="aboutfirst">
                <GatedLink to="/agenda" className="page login">
                  Agenda
                </GatedLink>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="abtext">
              <div className="venue-info">
                11.12.24 <span>| 11:00 AM - 2:00 PM ET</span>
                <br />
                <h2 className="virtHeader">VIRTUAL EVENT</h2>
              </div>
              Digital transformation is more essential now than ever for state
              and local governments, as well as higher education institutions.
              Only a few years removed from a period of crisis decision making
              and sped up timelines, technology leaders are continuing to chase
              modernization and digital expansion and ready to embrace the
              change that comes along with it.
              <br />
              <br />
              In addition, citizens continue to clamor for a better digital
              experience, meaning government agencies and universities are
              required to modernize and transform at the same time, all while
              trying to keep up with — and embrace — emerging technologies like
              artificial intelligence.
              <br />
              <br />
              This virtual summit will bring together technology leaders from
              across higher education, as well as state and local governments to
              explore the ongoing challenges they face while embracing IT
              modernization and digital transformation. Leaders will gather to
              discuss improving digital services, modernizing technology,
              embracing change and developing a workforce to support
              future-ready growth.
              <br />
              <br />
            </div>
            <div className="about-text">
              <div className="aboutfirst">
                <LoginButton />
              </div>
              <div className="aboutfirst">
                <Link to="/#becomeasponsor" className="page login">
                  Sponsor
                </Link>
              </div>
              <div className="aboutfirst">
                <GatedLink to="/agenda" className="page login">
                  Agenda
                </GatedLink>
              </div>
            </div>
          </>
        )}
      </div>
    </ArticleBar>
  );
};

export default Articles;
