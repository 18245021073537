import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import Main from "../components/main";
import SEO from "../components/seo";
import { trackPageview } from "../services";
import { mergeWithInitialQuery } from "../utils";

const IndexPage = () => {
  const location = useLocation();

  useEffect(() => {
    const query = mergeWithInitialQuery(location.search);
    const url = `${location.pathname}?${query}`;
    trackPageview(url);
  }, []);

  return (
    <Layout className="page__home">
      <Main />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => {
  return (
    <SEO>
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
