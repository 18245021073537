import styled from "styled-components";

export const HomePageWrapper = styled.div`
  display: flex;
  justify-content: center;

  .welcome-message {
    padding: 130px 120px 40px 0px;

    div.branding {
      max-width: 615px;
      img {
        width: 100%;
      }
    }

    h1 {
      font-family: "Metropolis", sans-serif;
      font-size: 36px;
      text-transform: uppercase;
      margin: 0;
      color: black;
      margin: 20px 0px 40px 0px;
      letter-spacing: 5px;
    }

    p {
      font-family: "Metropolis", sans-serif;
      font-size: 28px;
      line-height: 1.4;
      max-width: 570px;
    }
  }

  .shortcuts {
    // padding-top: 285px;
    padding-top: 126px;
    color: #fff;
    max-width: 470px;
  }

  @media only screen and (min-width: 1200px) {
    /*.welcome-message {
      margin-right: 115px;
    }*/
  }

  // @media only screen and (min-width: 981px) and (max-width: 1200px){
  //   .welcome-message{
  //     padding-left: 50px;
  //   }
  //   .shortcuts{
  //     padding-right: 50px;
  //   }
  // }

  @media only screen and (min-width: 768px) and (max-width: 980px) {
    .welcome-message {
      padding-left: 25px;
      padding-right: 100px;
    }
    .shortcuts {
      padding-right: 52px;
      padding-top: 126px !important;
    }
    .socials {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    display: block;

    h1 {
      line-height: 50px !important;
    }

    br {
      display: none;
    }

    .welcome-message {
      padding: 15px;
      padding-bottom: 0px;
      margin-right: 0px !important;
      padding-top: 20px;

      div.branding {
        max-width: 100%;
      }

      a {
        margin: 50px 0px 0px 0px;
      }

      h1 {
        font-size: 22px;
        padding-right: 20px;
        // margin-left: 15px;

        margin: 20px 0px 14px 15px;
      }
      p {
        font-size: 17px;
      }
    }
    .shortcuts {
      padding-top: 30px;
      padding-left: 15px;
      padding-right: 17px;
      max-width: 100%;
    }
    .socials {
      display: none;
    }
  }
`;

export const PrimaryButton = styled.div`
  a {
    position: relative;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    font-family: "Metropolis-SemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: 7px;
    width: 343px;
    margin: 50px 0px 0px 0px;
    border-radius: 0px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    background-image: linear-gradient(to left, #cb0acc, #8733ff, #6f0bd9);
    background-size: 200%;
    transition: 0.6s;
  }

  a:hover {
    background-image: none;
    background-color: #000;
  }

  @media only screen and (max-width: 767px) {
    a {
      margin-top: 50px;
      width: 100%;
      font-size: 28px;
    }
  }
`;

export const SecondaryButton = styled.div`
  font-family: "RedHatDisplay-Bold", sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ee0000;
  background-color: #fff;
  text-transform: capitalize;
  width: 315px;
  text-align: center;
  border-radius: 0px;
  border: 2px solid #000;
  margin-bottom: 30px;
  transition: 0.3s;

  a {
    text-decoration: none;
    position: relative;
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    color: #000;
    transition: 0.3s;
  }

  &:hover {
    background: #000;
    a {
      color: white;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SocialSection = styled.div`
  margin-bottom: 8px;

  a {
    text-decoration: none;
    img {
      width: 42px;
    }
  }
`;
