import styled from "styled-components";

export const ArticleBar = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #000;
  z-index: 0;
  margin-bottom: 0px;
  padding-top: 50px;
  height: 97%;
  .titlehead {
    font-family: "Inter", sans-serif;
    font-weight: 900;
    font-size: 48px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    line-height: 1.1;
    color: #000;
  }
  .aboutfirst:first-of-type {
    background-color: #000 !important;
    color: #fff !important;
    border: none !important;
    a {
      color: #fff !important;
      background-color: #000 !important;
    }
    a:hover {
      background-color: #fff !important;
      color: #000 !important;
      border: 1px solid #000 !important;
    }
  }
  .aboutfirst {
    font-family: "sofia-pro", sans-serif;
    font-size: 19px !important;
    color: #000 !important;
    background-color: #fff !important;
    text-transform: uppercase;
    width: 100%;
    max-width: 325px;
    text-align: center;
    border-radius: 0px;
    border: 1.6px solid #000 !important;
    margin-top: 0px;
    margin-bottom: 18px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    box-sizing: border-box;
    font-weight: 900 !important;
    a {
      position: relative;
      display: block;
      text-decoration: none;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #000 !important;
      font-weight: 900 !important;
    }
    a:hover {
      background-color: #000 !important;
      color: #fff !important;
    }
  }
  .venue-info {
    color: #000;
    font-weight: 700;
    font-size: 26px;
  }
  .venue-info span {
    font-weight: 400;
  }
  .venue-info .virtHeader {
    font-size: 21px;
    font-weight: 400;
    display: block;
    border-bottom: 4px solid #000;
    width: fit-content;
    padding-bottom: 10px;
    color: #000;
  }
  .leftText {
    text-align: left;
  }
  .artImage {
    max-width: 400px;
    width: 100%;
    float: left;
    margin-right: 30px;
    min-height: 237px;
    max-height: 237px;
    margin-bottom: 10px;
  }
  div.artImage {
    float: none;
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 60px;
  }
  .clearMe {
    clear: both;
  }
  .socialGrid {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .purpHeader {
    font-family: "Gilroy-ExtraBold", sans-serif;
    font-size: 115px;
    color: #8733ff;
    letter-spacing: 1px;
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 100px;
  }
  .purpleMe {
    color: #7f35b2 !important;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }
  .posts,
  .impressions {
    font-size: 24px;
    line-height: 32px;
  }
  .posts .purpleMe,
  .impressions .purpleMe,
  .speakName {
    font-family: "halyard-display", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #00bceb;
    text-transform: uppercase;
  }
  .speakTitle {
    color: #051526;
  }
  .articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
    a {
      text-decoration: none;
    }
    max-width: 1200px;
    margin: 0 auto;
  }
  .wrapUp {
    display: grid;
    grid-template-columns: 4fr 2fr;
    column-gap: 10vw;
    line-height: 26px;
    max-width: 1200px;
    margin: 0px auto;
    font-size: 22px;
  }
  .articleWrap {
    max-width: 345px;
  }
  .articleImage {
    display: block;
    width: 100%;
    height: 194px;
  }
  .wrapVid {
    max-width: 100%;
    width: 400px;
    min-height: 280px;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    vertical-align: top;
  }
  .innerVid {
    min-height: 280px;
  }
  .titleBlock {
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 24px;
    background-color: #8733ff;
    color: #fff;
    padding: 16px 22px;
    height: 75px;
  }
  @media only screen and (min-width: 1441px) and (max-width: 1600px) {
  }
  @media only screen and (max-width: 1199px) and (min-width: 768px) {
    .articles {
      grid-template-columns: 1fr 1fr;
      max-width: 680px;
    }
    .purpHeader {
      max-width: 680px;
    }
    .articleImage {
      max-height: 180px;
    }
    .titleBlock {
      height: 100px;
    }
  }
  @media only screen and (min-width: 374px) and (max-width: 767px) {
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
    }
    .wrapUp {
      grid-template-columns: 1fr;
      max-width: 95% !important;
    }
    .about-text {
      grid-row: 1;
    }
    .aboutfirst {
      max-width: 100%;
    }
    .purpHeader {
      font-size: 40px;
      max-width: 345px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .wrapVid {
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 373px) {
    .wrapVid {
      margin-right: 0px;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .purpHeader {
      font-size: 35px;
      max-width: 325px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .articleImage {
      max-height: 180px;
    }
    .titleBlock {
      height: 100%;
    }
  }
  @media only screen and (min-width: 281px) and (max-width: 359px) {
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .socialGrid {
      grid-template-columns: 100px 1fr;
    }
    .wrapVid {
      margin-right: 0px;
    }
    .purpHeader {
      font-size: 35px;
      max-width: 290px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .articleImage {
      max-height: 150px;
    }
    .titleBlock {
      height: 100%;
    }
  }
  @media only screen and (min-width: 120px) and (max-width: 280px) {
    .wrapVid {
      margin-right: 0px;
    }
    .titlehead {
      line-height: 1;
    }
    .socialGrid {
      grid-template-columns: 100px 1fr;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 260px;
      padding-left: 15px;
      padding-left: 15px;
    }
    .purpHeader {
      font-size: 15px;
      max-width: 260px;
      margin-bottom: 30px;
      margin-top: 0px;
      padding-left: 15px;
      padding-left: 15px;
    }
    .articleImage {
      max-height: 150px;
    }
    .titleBlock {
      height: 100%;
    }
  }
`;
